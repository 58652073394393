/* eslint-disable react/no-unescaped-entities */
import React from 'react';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
// import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

const mock = [
  {
    
    description:
      'Inhouse MUN',
    href : '/inhouse-mun-2024'
 
  },
  {
    
    description:
      'MAD Ads-Éclat - Class 6 and 7',
    href : '/mad-ads-eclat-2024'
 
  },
  {
    
    description:
      'Moot Court',
    href : '/moot-court-2024'
 
  },
  {
    
    description:
      'Rendezvous and Expert Insight',
    href : '/rendezvous-Expert-insight-2024'
 
  },
  {
    
    description:
      'Constitution Day 2024',
    href : '/constitution-day-2024'
 
  },
  {
    
    description:
      'Social Studies CCA 3 to 5 2024',
    href : '/social-cca-3-5-2024'
 
  },


];

const Sidebar2024 = () => {
  
  return (
    <Box component={Card} variant={'outlined'} padding={2}>
      <Typography
        variant="h6"
        data-aos={'fade-up'}
        sx={{
          fontWeight: 700,
          marginBottom: 2,
        }}
      >
        Humanity Club 2024-25
      </Typography>
      <Grid container spacing={2}>
        {mock.map((item, i) => (
          <Grid key={i} item xs={12}>
            <Box
              component={Card}
              width={1}
              height={1}
              boxShadow={0}
              borderRadius={0}
              display={'flex'}
              flexDirection={{ xs: 'column', md: 'row' }}
              sx={{ backgroundImage: 'none', bgcolor: 'transparent' }}
            >
              <Button href={item.href}>
                <Typography variant='h6'>
                  {item.description}
                  
                </Typography>
              </Button>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Sidebar2024;
